import { useTranslation } from "react-i18next";
import { imageAlt } from "../../../config/accessabilityRules";
import { ACTION_LABELS } from "../../../config/dataDogActionLabels";
import CustomButton from "../../../components/CustomButton";

const ACTION = ACTION_LABELS.processResults;

const OutOfRetryAttemptsState = ( {
    pageFocusRef,
    isImageOrientationPortrait,
    cardImage,  
    proceedToNextStep,
}) => {
   
   const { t } = useTranslation();

    return  (
        <>
        <h1
            className={'description error'}
            ref={pageFocusRef}
            tabIndex={0}
        >
            {t('idpal_capture_front_side_of_doc')}
        </h1>
        <div className={`u-display-upload ${isImageOrientationPortrait() ? 'preview-container' : ''}`}>
            {cardImage && (
                <img
                    id='document-image'
                    alt={imageAlt.upLoadedDocument}
                    src={cardImage}
                    className='capture'
                />
            )}
        </div>
        <div className='document-review-cta-container'>
            <CustomButton
                id={'continue'}
                className={'btn'}
                label={t('idpal_submit')}
                handleClick={() => proceedToNextStep()}
                actionDataLabel={ACTION.continueButton}
            />
        </div>
    </>)};

export default OutOfRetryAttemptsState;