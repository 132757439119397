/**
 * @prettier
 */

// React Packages
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../store';

// Actions
import { setCardType } from '../../screens/actions/idPropertiesActions';

// Services
import documentService from '../../services/shared/documentService';
import { isIdPalCameraEnabled } from '../../services/shared/helpers';

// Components
import Header from '../../components/Header';
import Navigator from '../../screens/Navigator';

import OutOfRetryAttemptsState from './components/OutOfRetryAttemptsState';
import DocumentUploadCompletedState from './components/DocumentUploadCompletedState';
import CustomButton from '../../components/CustomButton';
import SubmissionFlowStepper from '../../components/SubmissionFlowStepper';

import DriversLicenseFrontIcon from './components/DynamicColoredIcons/DriversLicenseFrontIcon';
import DriversLicenseBackIcon from './components/DynamicColoredIcons/DriverLicenseBackIcon';
import PassportOneSideFlowIcon from './components/DynamicColoredIcons/PassportOneSideFlowIcon';
import PassportTwoSidesFlowIcon from './components/DynamicColoredIcons/PassportTwoSidesFlowIcon';

// Config
import { ACTION_LABELS } from '../../config/dataDogActionLabels';

// Constants
const ACTION = ACTION_LABELS.instructions;

class Instructions extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.paperPassportFocusRef = React.createRef();
        this.state = {
            isLandscape: false,
            navigation: {
                action: 'load',
                props: null,
            },
        };

        this.continue = this.continue.bind(this);
        this.brandingColor = `#${this.props.branding.data.company_branding.primary_color}`;
    }
    getDocumentType() {
        const { t } = this.props;
        if (this.props.match.params.type === '1') {
            return t('idpal_passport');
        } else if (this.props.match.params.type === '31') {
            return t('idpal_driving_licence');
        } else if (this.props.match.params.type === '4') {
            return t('xxx_id_card');
        } else {
            console.error('Invalid document type');
       }
    }
    componentDidMount() {
        // Sets focus on screen transition
        if (this.showPaperLicence()) {
            // Sets to first link
            if (
                this.paperPassportFocusRef &&
                this.paperPassportFocusRef.current
            ) {
                this.paperPassportFocusRef.current.focus();
            }
        } else {
            // if no link, sets to h1 tag
            if (this.primaryFocusRef && this.primaryFocusRef.current) {
                this.primaryFocusRef.current.focus();
            }
        }

        // Sets instruction screen orientation based on if idpal capture is enabled
        this.setImageOrientation();

        // Sets document title
        const { t } = this.props;
        if (['31', '0'].includes(this.props.match.params.type)) {
            document.title = t('idpal_doc_title_instructions_driving');
        } else {
            document.title = t('idpal_doc_title_instructions_passport');
        }
    }

    getInstructions() {
        const { t } = this.props;

        // returns instructions messages based on if manual or auto capture is supported by the device
        if (
            this.props.match.params.type === '1' &&
            this.props.onePagePassport === 0
        ) {
            return t('idpal_generic_document_instructions', {documentType: this.getDocumentType()});
        } else if (this.props.match.params.side === '1') {
            return this.props.autoIdCaptureSupported
                ? t('idpal_auto_id_capture_instructions')
                : t('idpal_generic_document_instructions', {documentType: this.getDocumentType()});
        } else {
            if (this.props.match.params.type === '31') {
                return this.props.autoIdCaptureSupported
                    ? t('idpal__auto_drivers_license_back_instructions')
                    : t('idpal_generic_document_back_side_instruction', {documentType: this.getDocumentType()});
            } else {
                return this.props.autoIdCaptureSupported
                    ? t('idpal_auto_id_card_back_instructions')
                    : t('idpal_id_card_back_instructions');
            }
        }
    }    
    isTwoPagePassport() {
        return (
            (this.props.match.params.type === '1' &&
                this.props.onePagePassport === 0) ||
            this.props.isExpiredTwoPagePassport
        );
    }
    showPaperLicence() {
        // First check if we are in the us & paper licence is enabled
        // TODO: (Rhiannon): Check if this takes precedence over document selection in the profile
        if (process.env.REACT_APP_PAPER_LICENCE_ENABLED === 'false') {
            return false;
        }

        // Second check if we are on the DL screen & it is the first page.
        const isDrivingLicenceInstructions =
            ['31', '0'].includes(this.props.match.params.type) &&
            this.props.match.params.side === '1';

        // Third check if paper licence is accepted
        const isPaperLicenceSelected = this.props.acceptedDocuments.includes(
            'laminated_paper_license'
        );

        if (this.props.isDocSelectionEnabled) {
            if (isDrivingLicenceInstructions && isPaperLicenceSelected) {
                return true;
            }
        } else {
            return isDrivingLicenceInstructions;
        }
    }

    setImageOrientation() {
        if (this.props.idpalOverlaySupported) {
            this.setState({
                isLandscape: true,
            });
        }
    }

    continue(overrideDocument) {
        let documentId = this.props.location.state.documentId;

        // If we are continuing with a different kind of document than originally selected
        if (overrideDocument !== '') {
            const document = documentService.findByName(overrideDocument);
            this.props.setCardType(document.id);
            documentId = document.id;
        }

        this.setState({
            navigation: {
                action: 'next',
                props: {
                    documentId: documentId,
                    isRetry: this.props.location.state.isRetry,
                    isIdPalCamera:
                        isIdPalCameraEnabled(this.props.captureMethod) ||
                        this.props.bypassAcuant,
                },
            },
        });
    }

    render() {
        const { t } = this.props;
        // Document upload is already completed
        if (this.props.completed) {
            return (
                <DocumentUploadCompletedState
                    onContinue={() => this.setState({ navigation: { action: 'next' } })}
                    message='idpal_document_captured'
                    navigationAction={this.state.navigation.action}
                    navigationProps={this.state.navigation.props}
                />               
            );
        }

        // Out of retries, and we've already tried this side
        if (
            this.props.submissionAttempts.remaining <= 0 &&
            this.props.location.state.sidesLeft === 0 &&
            this.props.submissionAttempts[
                this.props.location.state.sidesLeft === 2 ? 'front' : 'back'
            ] > 0
        ) {    
            return (
                <OutOfRetryAttemptsState
                    onContinue={() => {history.goForward()}}
                    message='idpal_out_of_attempts'
                    navigationAction={this.state.navigation.action}
                    navigationProps={this.state.navigation.props}
                />
                
            );
        }

        return (
            <div className='root-layout-container instructions-page'>
                <Header />
                <SubmissionFlowStepper/>
                <h1 
                    className='instructions-title'                       
                    ref={this.primaryFocusRef}
                    tabIndex={0}>
                        {this.getInstructions()}
                </h1>
                {/* Passport Images */}
                {this.props.match.params.type === '1' && (
                    <>
                        {/* Single Page Passport */}
                        {this.props.onePagePassport === 1 && (
                            <PassportOneSideFlowIcon fill={this.brandingColor}/>                           
                        )}

                        {/* Two Page Passport */}
                        {this.props.onePagePassport === 0 && (
                            <PassportTwoSidesFlowIcon fill={this.brandingColor} />
                        )}
                    </>
                )}



                {['31', '0'].includes(
                    this.props.match.params.type
                ) &&
                    this.props.match.params.side === '1' && (
                        <DriversLicenseFrontIcon fill={this.brandingColor}/>
                    )}
                {this.props.match.params.type === '31' &&
                    this.props.match.params.side === '2' && (
                        <DriversLicenseBackIcon fill={this.brandingColor}/>
                    )}
                {this.props.match.params.type === '4' &&
                    this.props.match.params.side === '1' && (
                        <DriversLicenseFrontIcon fill={this.brandingColor}/>
                    )}
                {this.props.match.params.type === '4' &&
                    this.props.match.params.side === '2' && (
                        <DriversLicenseBackIcon fill={this.brandingColor}/>
                )}
                    
                <div className='instructions-page-cta-container'> 
                    <CustomButton
                        id='continue'
                        className='btn btn__arrow'
                        label={t("idpal_continue")}
                        handleClick={() => this.continue('')}
                        actionDataLabel={ACTION.openCameraButton}
                    /> 
                </div>
                <Navigator
                    page={'instructions'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        autoIdCaptureSupported: state.config.autoIdCaptureSupported,
        bypassAcuant: state.config.bypassAcuant,
        submissionAttempts: state.submissionState.submissionAttempts,
        completed:
            state.submissionState.submissionState.document_upload.completed,
        idpalOverlaySupported: state.config.idpalOverlaySupported,
        captureMethod:
            state.submissionState.screens.document_upload.capture_method,
        onePagePassport:
            state.submissionState.screens.document_upload.one_page_passport,
        isExpiredTwoPagePassport: state.idProperties.isExpiredTwoPagePassport,
        isDocSelectionEnabled: state.submissionState.isDocSelectionEnabled,
        acceptedDocuments: state.config.profile.data.accepted_documents,
        branding: state.config.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setCardType }, dispatch);
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(Instructions)
);
