/**
 * @prettier
 */

// React Packages
import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { setInstanceID } from '../screens/actions/configActions';

const CustomButton = ({branding = null, isDisabled = false, className='', outlined = false, ...props}) => {
    const [colorState, setColorState] = useState(null);
    const [isHovered, setIsHovered] = useState(false);

    const handleButtonClick = () => {
        props.handleClick();
    };
    
    useEffect(() => {
        if (branding) {
            setColorState(
                branding.data.company_branding.primary_color
                    ? branding.data.company_branding.primary_color
                    : colorState
            );
        }
    }, [branding, colorState]);


    const buttonStyles = useMemo(() => {
        let styles = {
            color: outlined? `#${colorState}` : '#ffffff',
            backgroundColor: outlined? '#ffffff': `#${colorState}`,
            boxShadow: outlined? 'none' : `inset 0px 0px 0px 2px #${colorState}`,
            border: 'none',
            fontFamily: 'Open Sans',
        };
        if(isHovered){
            styles = {
                ...styles,
                color: `#${colorState}`,
                backgroundColor: '#ffffff',
                transition: 'all 300ms ease-in-out',

            };
        }
        if(isDisabled){
            styles = {
                ...styles,
                backgroundColor: '#d3d3d3',
                boxShadow: 'none',
            };
        }
        return styles;
    }, [colorState, isHovered, isDisabled, outlined]);

    return (
        <button
            type={props.type}
            id={props.id}
            style={buttonStyles}
            className={`cta-button ${className}`}
            onClick={handleButtonClick}
            onMouseEnter={()=> setIsHovered(true)}
            onMouseLeave={()=> setIsHovered(false)}
            disabled={props.isDisabled}
            data-dd-action-name={props.actionDataLabel}
        >
            {props.label}
        </button>
    );
}
function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        branding: state.config.profile,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setInstanceID }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomButton);