import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/CustomButton';
import Header from '../../../components/Header';
import SubmissionFlowStepper from '../../../components/SubmissionFlowStepper';


const IDPalCameraCapture = ({
    flashCamera,
    getDimensions,
    isTwoPagePassport = false,
    primaryFocusRef,
    scrollToRef,
}) => {
    const { t } = useTranslation();    
    return (
        <>
        <div className="root-layout-container">
            <Header/>
            <SubmissionFlowStepper/>
                <div className="idpal-capture-container">
                    <div className={isTwoPagePassport ? 'capture-content vertical' : 'capture-content'}>                  
                            <h1
                                ref={primaryFocusRef}
                                tabIndex={0}
                                className='idpal-capture-title'>
                                {t('idpal_capture_image_sdk_title')}
                            </h1>
                            <div
                                className='content-capture'                          
                                ref={isTwoPagePassport? scrollToRef: null}>
                                <div className='canvas-container'>
                                    <div className='capture-brackets'></div>
                                    <div className='capture-guide'></div>
                                    <video
                                        id='my-video'
                                        controls
                                        autoPlay
                                        playsInline
                                        style={{ display: 'none' }}
                                    />                                
                                    <canvas                                   
                                        id='my-canvas'
                                        height={getDimensions().height}
                                        width={getDimensions().width}
                                    ></canvas>
                                </div>
                            </div>                                            
                    </div>              
                    <div className='btn-container'>
                        <CustomButton
                            handleClick={() => flashCamera()}
                            actionDataLabel={t('idpal_take_picture')}
                            label={t('idpal_take_picture')}                        
                        />
                    </div>                
                </div>
            </div>
        </>
        );
}
export default IDPalCameraCapture;