import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/CustomButton";

import { ACTION_LABELS } from '../../../config/dataDogActionLabels';
import { imageAlt } from '../../../config/accessabilityRules';

const ACTION = ACTION_LABELS.processResults;

const DocumentReviewState = ( {
    data,
    classification,
    pageFocusRef,
    validation,
    isImageOrientationPortrait = () => {},
    cardImage,
    isTwoPagePassport = () => {},
    getImageData = () => {},
    isAcceptedDoc,
    proceedToNextStep = () => {},
    retryDocumentUpload = () => {},
}) => {
const { t } = useTranslation();

const ErrorMessageContainer = ({message}) => {
    return (
        <h1
            className={'error-message-text'}
            ref={pageFocusRef}
            tabIndex={0}
        >
            {message}
        </h1>
    )
}
return (   
    <>
        {!classification.classified &&
            classification.messages && (
                <ErrorMessageContainer message={classification.messages}/>                                   
            )}

        {((!validation.validated && validation.messages) ||
            (validation.validated && classification.classified)) && (
                /* TODO message should correspond to validation.message[0] */                
                <ErrorMessageContainer message={t("idpal_capture_back_side_of_doc")}/>                                    
        )}
        <div className={`u-display-upload ${isImageOrientationPortrait() ? 'preview-container' : ''}`} >
            {cardImage && (
                <img
                    id='document-image'
                    alt={imageAlt.upLoadedDocument}
                    src={
                        isTwoPagePassport()
                            ? getImageData()
                            : cardImage
                    }
                    className='capture'
                />
            )}
        </div>

        <div className='document-review-cta-container'>
            {(!data.orientation ||
                    !classification.classified ||
                    !isAcceptedDoc) && (
                    <CustomButton
                        id={'retry'}
                        type='customHover'                               
                        label={t('idpal_retry')}
                        handleClick={() =>
                            retryDocumentUpload(true)
                        }
                        actionDataLabel={
                            ACTION.retryUploadButton
                        }
                        outlined
                    />
            )}
            {validation.validated &&
                isAcceptedDoc && (
                    <>                       
                        <CustomButton
                            id={'continue'}                                   
                            label={t(
                                'idpal_submit'
                            )}
                            handleClick={() =>
                                proceedToNextStep()
                            }
                            actionDataLabel={
                                ACTION.continueButton
                            }
                        />
                    </>                            
            )}                    
        </div>
    </>
)}
export default DocumentReviewState;