/**
 * @prettier
 */

// React Packages
import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

// Actions
import { setCaptured } from '../../screens/actions/capturedActions';
import { setRetryLimit } from '../../screens/actions/submissionStateActions';
import { setOverlayDisabled } from '../../screens/actions/configActions';
import { setCapturedDocumentImage } from '../../screens/actions/idPropertiesActions';

// Services
import logService from '../../services/shared/logService';
import DataDogService from '../../services/shared/datadogService';
import {
    bytesToSize,
    calculateImageFileSize,
    isIOS,
} from '../../services/shared/helpers';

// Components
import Header from '../../screens/Header';
import Navigator from '../../screens/Navigator';
import CustomButton from '../../screens/Button';
import StageComplete from '../../screens/Messages/StageComplete';
import OutOfRecaptures from '../../screens/Messages/OutOfRecaptures';
import InstructionModal from '../../screens/Messages/InstructionModal';
import IDPalCameraCapture from './components/IDPalCapture';

// Images
import passportGif from '../../assets/img/loading.gif';
import idGif from '../../assets/gifs/loading_idcard.gif';
import loadingGif from '../../assets/gifs/loading.gif';

// Config
import { ACTION_LABELS } from '../../config/dataDogActionLabels';
import { imageAlt } from '../../config/accessabilityRules';
import { isMobile, isTablet } from 'react-device-detect';
import { history } from '../../store';
import { environment } from '../../config/environment';

// Constants
const ACTION = ACTION_LABELS.acuantCamera;

// Global
let loadingTimer = null;
let fallbackTimer = null;
let localStream = null;

const smoothLoading = () => {
    if (!loadingTimer) {
        const loader = document.getElementById('loader');
        const retry = document.getElementById('retry');
        if (loader && loader.classList) {
            loader.classList.remove('hidden');
        }
        if (retry && retry.classList) {
            retry.classList.add('hidden');
        }

        loadingTimer = setTimeout(() => {
            document.body.onfocus = null;
            const backButton = document.getElementById('back');
            if (backButton) {
                backButton.click();
            }
        }, 1000);
    }
}

const clearLoading = () => {
    document.body.onfocus = null;
    const loader = document.getElementById('loader');
    if (loader) {
        loader.classList.add('hidden');
    }
    clearTimeout(loadingTimer);
    loadingTimer = null;
}

// Calculate if image file-size is above the max-file size & log size to datadog
const isLargeImage = image => {
    const maxFileSize = 5.0;
    const imageSizData = bytesToSize(calculateImageFileSize(image));
    DataDogService.log(`Captured Image Size: ${imageSizData.size} ${imageSizData.unit}`);
    logService.log(`Captured Image Size: ${imageSizData.size} ${imageSizData.unit}`);
    if (imageSizData.unit === 'GB') {
        return true;
    }
    if (imageSizData.unit === 'MB' && imageSizData.size > maxFileSize) {
        return true;
    }
    return false;
};

const CANVAS_DIMENSIONS = {
    width: 3584,
    height: 2688,
};

const VERTICAL_CANVAS_DIMENSIONS = {
    width: 2688,
    height: 3584,
};
const CaptureDocumentPage = ( {
    submissionAttempts,
    sidesLeft,
    idpalOverlaySupported = true,
    onePagePassport,
    isExpiredTwoPagePassport,
    setCapturedDocumentImage,
    cardType,
    location,
    completed,
}) => {
const [processing] = useState(false);
const [isCameraInit, setIsCameraInit] = useState(false);
const [receivedImage, setReceivedImage] = useState(false);
const [isOverlayImageLoaded, setIsOverlayImageLoaded] = useState(false);
const [isNativeImageLoaded, setIsNativeImageLoaded] = useState(false);
const [flashClassName, setFlashClassName] = useState('');
const [flashed, setFlashed] = useState(false);
const [overlayError, setOverlayError] = useState(false);
const [nativeImage, setNativeImage] = useState(null);
const [downscaledNativeImage, setDownscaledNativeImage] = useState(null);
const [overlayImage, setOverlayImage] = useState(null);
const [imageHeight, setImageHeight] = useState(CANVAS_DIMENSIONS.height);
const [imageWidth, setImageWidth] = useState(CANVAS_DIMENSIONS.width);
const [showModal, setShowModal] = useState(false);
const [isMounted, setIsMounted] = useState(false);
const [constraints, setConstraints] = useState({
    video: {
        facingMode: { exact: 'environment' },
        height: { ideal: 1440 },
        width: { ideal: 1440 },
    },
});
const analyzingGif = cardType === 1 ? passportGif : idGif;
const fallbackTimeLimit = 5000;
const [navigation, setNavigation] = useState({
    action: 'load',
    props: null,
});
const primaryFocusRef = useRef();
const scrollToRef = useRef();
const { t } = useTranslation();


const handleCancel = () => {
    if (!receivedImage) {
        goBack();
    }
}
const goBack = () => {
    setNavigation({
        action: 'back',
        props: {
            documentId: cardType,
            sidesLeft: sidesLeft,
        },
    });
}
const isTwoPagePassport = () => ((cardType === 1 && onePagePassport === 0) || isExpiredTwoPagePassport);

const getDimensions = () => {
    if (isTwoPagePassport()) {
        return VERTICAL_CANVAS_DIMENSIONS;
    } else {
        return CANVAS_DIMENSIONS;
    }
};


/**
 * Overlay functionality starts here
 **/

const initiateOverlayCamera = () => {
    const canvas = document.querySelector('#my-canvas');
    playOverlayCamera(canvas);
};

const getCameraID = () => {
    const devices = navigator.mediaDevices;
    if (devices && 'getUserMedia' in devices) {
        if (!devices?.enumerateDevices) {
            logService.error('enumerateDevices() not supported.');
            prepareNativeCapture();
        } else {
            let videoInputIDs = null;
            devices
                .enumerateDevices()
                .then(devices => {
                    // Select only video inputs
                    videoInputIDs = devices.filter(device => {
                        if (device.kind === 'videoinput') {
                            return device.deviceId;
                        } else {
                            return null;
                        }
                    });

                    /*
                    Set the device ID to the last available video input
                    For devices with multiple cameras this is the standard environment facing camera.
                */
                    videoInputIDs.length !== 0 &&
                        setConstraints({
                            video: {
                                ...constraints.video,
                                width: { ideal: imageWidth },
                                height: { ideal: imageHeight },
                                deviceId: {
                                    exact: videoInputIDs[videoInputIDs.length - 1].deviceId,
                                },
                            },
                        });
                    setIsCameraInit(true);
                })
                .catch(error => {
                    logService.error(`${error.name}: ${error.message}`);
                    prepareNativeCapture();
                });
        }
    }
};
const playOverlayCamera = canvas => {
    const devices = navigator.mediaDevices;
    if (devices && 'getUserMedia' in devices) {
        const promise = devices.getUserMedia(constraints);
        promise
            .then(function (stream) {
                fallbackTimer = setTimeout(prepareNativeCapture, fallbackTimeLimit);
                const video = document.getElementById('my-video');
                video.addEventListener('loadedmetadata', function () {
                    const context = canvas.getContext('2d');
                    const drawFrame = function () {
                        clearTimeout(fallbackTimer);
                        const width = CANVAS_DIMENSIONS.width;
                        const height = (width / video.videoWidth) * video.videoHeight;
                        context.clearRect(0, 0, canvas.width, canvas.height);
                        context.drawImage(video, 0, 0, width, height);

                        isMounted && requestAnimationFrame(drawFrame);
                    };
                    isMounted && drawFrame();
                });
                DataDogService.log('Capturing with ID Pal capture overlay.');
                video.autoplay = true;
                video.srcObject = stream;
                localStream = stream;
            })
            .catch(function (error) {
                prepareNativeCapture();
                logService.error(`${error.name}: ${error.message}`);
            });
    } else {
        prepareNativeCapture();
        logService.error('Camera API is not supported.');
    }
};
const captureOverlayImage = () => {
    // Stop the camera stream if running
    if (localStream && localStream?.getVideoTracks()) {
        const tracks = localStream.getVideoTracks();
        tracks[0].stop();
        localStream = null;
    }

    // Capture the image
    const canvas = document.querySelector('#my-canvas');
    const image = canvas.toDataURL('image/jpeg');
    setOverlayImage(image);
    setIsOverlayImageLoaded(true);

    // Clear the video src
    const video = document.getElementById('my-video');
    video.srcObject = null;
    DataDogService.log('Image successfully captured with ID Pal capture overlay');
};

const proceedWithOverlayImage = () => {
    // First check if we are submitting with a large image
    if (isLargeImage(overlayImage)) {
        DataDogService.log('Submitting with large image');
    }
    setCapturedDocumentImage(overlayImage, 'uncropped');
    const properties = {
        imageSize: 123, // Placeholders. Either remove, or get proper values
        width: CANVAS_DIMENSIONS.width,
        height: CANVAS_DIMENSIONS.height,
        originalWidth: CANVAS_DIMENSIONS.width,
        originalHeight: CANVAS_DIMENSIONS.height,
        properties: {
            sharpness: 100, // Placeholders. Either remove, or get proper values
            glare: 100, // Placeholders. Either remove, or get proper values
            dpi: 500, // Placeholders. Either remove, or get proper values
            documentId: location.state.documentId,
            isPassport: 1, // Placeholders. Either remove, or get proper values
        },
    };
    DataDogService.log('Proceeding with ID Pal overlay image.');
    setNavigation({
        action: 'next',
        props: properties,
    });
};

// Delays capturing the image until camera flash animation has started
const flashCamera = () => {
    setFlashClassName('flash');
    setTimeout(() => {
        // Triggers capture through state change
        setFlashed(true);
    }, 500);
};

const detectOrientation = () => {
    window.matchMedia('(orientation: portrait)').addEventListener('change', e => {
        const portrait = e.matches;
        if (portrait) {
            setShowModal(false);
        } else {
            setShowModal(true);
        }
    });
};
const scrollToElement = () =>  scrollToRef.current?.scrollIntoView({ behavior: 'smooth' });

/**
 * Native capture functionality starts here
 * */
const initiateNativeCapture = () => {
    setOverlayError(false);
    const nativeCameraInput = document.getElementById('readUrl');
    if (nativeCameraInput) {
        nativeCameraInput.click();
    }
};

const prepareNativeCapture = () => {
    // Stop the camera stream if running
    if (localStream && localStream?.getVideoTracks()) {
        const tracks = localStream.getVideoTracks();
        tracks[0].stop();
        localStream = null;
    }

    const video = document.getElementById('my-video');

    if (video) {
        video.srcObject = null;
    }

    // The first time on mobile, show error
    if (idpalOverlaySupported && (isMobile || isTablet)) {
        setOverlayError(true);
    }
    setOverlayDisabled();
};

const downscaleImage = image => {
    DataDogService.log('Large image submitted. Image was Downscaled.');
    let width = image.width;
    let height = image.height;
    const MAX_WIDTH = 3584; // taken from the MAX_WIDTH property in the acuant SDK
    const MAX_HEIGHT = 2688; // taken from the MAX_HEIGHT property in the acuant SDK

    // Change the resizing logic based on the initial aspect ratio
    if (width > height) {
        if (width > MAX_WIDTH) {
            height = height * (MAX_WIDTH / width);
            width = MAX_WIDTH;
        }
    } else {
        if (height > MAX_HEIGHT) {
            width = width * (MAX_HEIGHT / height);
            height = MAX_HEIGHT;
        }
    }

    // Uses hidden canvas to downscale the image
    const hiddenCanvas = document.querySelector('#hiddenCanvas');
    hiddenCanvas.width = width;
    hiddenCanvas.height = height;
    const ctx = hiddenCanvas.getContext('2d');

    // Settings taken from the acuant SDK to ensure sharpness is retained
    ctx.mozImageSmoothingEnabled = false;
    ctx.webkitImageSmoothingEnabled = false;
    ctx.msImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;
    ctx.drawImage(image, 0, 0, width, height);
    const downscaled = hiddenCanvas.toDataURL('image/jpeg');
    return downscaled;
};

const nativeCapture = () => {
 DataDogService.log('Capturing with ID Pal capture native camera.');
    // Sets the focus to allow loading animation and back navigation if the camera is closed
    document.body.onfocus = smoothLoading;

    // Gets the image from the camera
    document.getElementById('readUrl').addEventListener('change', function () {
        if (this.files[0]) {
            const picture = new FileReader();
            picture.readAsDataURL(this.files[0]);
            DataDogService.log('Image successfully captured with ID Pal capture native camera');
            picture.addEventListener('load', function (event) {
                const image = new Image();
                image.src = event.target.result;
                setReceivedImage(true);
                setNativeImage(event.target.result);
                clearLoading();
                image.onload = function () {
                    // If image above the maxWith or maxHeight downscale to the same size as the overlay image
                    if (this.width > environment.maxWidth || this.height > environment.maxHeight) {
                        setDownscaledNativeImage(downscaleImage(this));
                    }
                    setImageWidth(this.width);
                    setImageHeight(this.height);
                    setIsNativeImageLoaded(true);
                };
            });
        } else {
            logService.error('No image uploaded');
        }
    });

}

const proceedWithImage = () => {
    // First log if we are submitting with a large image
    if (isLargeImage(nativeImage)) {
        DataDogService.log('Submitting with large image');
    }

    // Check if the image should be downscaled.
    if (downscaledNativeImage) {
        setCapturedDocumentImage(downscaledNativeImage, 'downscaled');
    } else {
        setCapturedDocumentImage(nativeImage, 'uncropped');
    }

    const properties = {
        imageSize: 123, // Placeholders. Either remove, or get proper values
        width: imageWidth,
        height: imageHeight,
        originalWidth: imageWidth,
        originalHeight: imageHeight,
        properties: {
            sharpness: 100, // Placeholders. Either remove, or get proper values
            glare: 100, // Placeholders. Either remove, or get proper values
            dpi: 500, // Placeholders. Either remove, or get proper values
            documentId: location.state.documentId,
            isPassport: 1,
        },
    };

    DataDogService.log('Proceeding with ID Pal native camera image.');

    setNavigation({
        action: 'next',
        props: properties,
    });
};


useEffect(() => {
    setIsMounted(true);
    if (
        submissionAttempts.remaining > 0 ||
        sidesLeft > 0 ||
        submissionAttempts[sidesLeft === 2 ? 'front' : 'back'] === 0
    ) {        
        clearLoading();
        if (idpalOverlaySupported && (isMobile || isTablet)) {
            scrollToElement();
            isIOS() ? initiateOverlayCamera() : getCameraID();
            detectOrientation();
        } else {
            prepareNativeCapture();
            initiateNativeCapture();
        }
    }

    // Sets focus to primary heading on first render
    if (primaryFocusRef && primaryFocusRef.current) {
        primaryFocusRef.current.focus();
    }
    // Set document title
    document.title = t('idpal_doc_title_capture_id');
    return () => {
        setIsMounted(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}, []);

useEffect(() => {
    if (primaryFocusRef && primaryFocusRef.current) {
        primaryFocusRef.current.focus();
    }
    if(overlayError){
        document.title = t('idpal_doc_title_capture_id_error');
    } else {
        document.title = t('idpal_doc_title_capture_id');
    }
}, [overlayError]);
useEffect(() => {
    if (isCameraInit) {
        initiateOverlayCamera();
    }
}, [isCameraInit]);
useEffect(() => {
    if (flashed) {
        captureOverlayImage();
    }
}, [flashed]);
useEffect(() => {
    if (isOverlayImageLoaded) {
        proceedWithOverlayImage();
    }
}, [isOverlayImageLoaded]);
useEffect(() => {
    if (isNativeImageLoaded) {
        proceedWithImage();
    }
}, [isNativeImageLoaded]);

if (completed) {
    return (
        <>
            <Header />
            <StageComplete
                continue={() => {setNavigation({action: 'next', props: null})}}
                message={t('idpal_stage_complete')}
            />
            <Navigator
                page="document_review"
                action={navigation.action}
                propsToPass={navigation.props}
            />
        </>
    )
}
if (submissionAttempts.remaining <= 0 &&
    sidesLeft === 0 &&
    submissionAttempts[sidesLeft === 2? 'front' : 'back'] > 0) {
    return (
        <>
            <Header />
            <OutOfRecaptures
                continue={() => history.goForward()}
                message={t('idpal_out_of_recaptures')}
            />
            <Navigator
                page="document_review"
                action={navigation.action}
                propsToPass={navigation.props}
            />
        </>
    )
}
   
    return (
        <>
           <div className={flashClassName}>
                {showModal && (
                    <InstructionModal 
                        message={t('idpal_change_orientation_message')}
                        heading={t('idpal_change_orientation')}
                        showCta={false}
                        isRotated={true}
                    />            
                )}
                {!idpalOverlaySupported && <Header />}
                <div
                    id={'loader'}
                    className='u-text-center start-loading hidden'
                >
                    <img
                        alt={imageAlt.loading}
                        src={loadingGif}
                        className='capture'
                    />
                </div>
                { idpalOverlaySupported && (isMobile || isTablet) && (
                    <IDPalCameraCapture
                        flashCamera={flashCamera}
                        getDimensions={getDimensions}
                        isTwoPagePassport={isTwoPagePassport()}
                        primaryFocusRef={primaryFocusRef}
                        scrollToRef={scrollToRef}
                    />
                )}
           </div>
           {!idpalOverlaySupported && (
            <>
                <div className='o-site-wrap'>
                    <div className='o-entryBtns'>
                        {overlayError && (
                            <>
                                <h1
                                    className='u-generic-text  u-text-center u-btm-buffer'
                                    ref={primaryFocusRef}
                                    tabIndex={0}
                                >
                                    {t('idpal_camera_failed_to_open')}
                                </h1>
                                <CustomButton
                                    id={'open_native_camera'}
                                    className={'btn hidden'}
                                    label={t('idpal_open_camera')}
                                    handleClick={() =>
                                        initiateNativeCapture()
                                    }
                                />
                            </>
                        )}
                    </div>
                </div>
            </>
           )}
         
           {processing ? (
                    <div className={'o-site-wrap'}>
                        <h1
                            className='u-generic-text u-text-center u-btm-buffer loading-ellipse'
                            ref={primaryFocusRef}
                            tabIndex={0}
                        >
                            {t('idpal_uploading')}
                            <span className='dot1'>.</span>
                            <span className='dot2'>.</span>
                            <span className='dot3'>.</span>
                        </h1>
                        <div className='u-display-analysing u-text-center'>
                            <img
                                alt={imageAlt.idCardLoading}
                                src={analyzingGif}
                                className='capture'
                            />
                        </div>
                    </div>) : 
                    (<div id={'loading'} className='o-site-wrap hidden'>
                        <div className='u-display-analysing u-text-center hidden'>
                            <CustomButton
                                id={'back'}
                                className={'btn hidden'}
                                handleClick={() => handleCancel()}
                                actionDataLabel={ACTION.backHiddenButton}
                            />
                        </div> 
                    </div>)}
                <input
                    className='hidden'
                    type='file'
                    id='readUrl'
                    accept='image/*'
                    capture
                    onClick={() => nativeCapture()}
                />
                {/* Hidden canvas for downscaling image */}
                <canvas className='hidden' id='hiddenCanvas'></canvas>
                <Navigator
                    page={'document_capture'}
                    action={navigation.action}
                    propsToPass={navigation.props}
                />
        </>
    )}


function mapStateToProps(state) {
    return {
        cardType: state.idProperties.cardType,
        submissionAttempts: state.submissionState.submissionAttempts,
        completed:
            state.submissionState.submissionState.document_upload.completed,
        cropSettings: state.config.cropSettings,
        sidesLeft: state.idProperties.sidesLeft,
        idpalOverlaySupported: state.config.idpalOverlaySupported,
        onePagePassport:
            state.submissionState.screens.document_upload.one_page_passport,
        isExpiredTwoPagePassport: state.idProperties.isExpiredTwoPagePassport,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setCaptured,
            setRetryLimit,
            setOverlayDisabled,
            setCapturedDocumentImage,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptureDocumentPage);
